import { Controller } from 'stimulus'

export default class extends Controller {
  toTop () {
    let btn = document.getElementById('button_top')
    if (btn.classList.contains('rotate-180')) {
      btn.classList.remove('rotate-180')
      window.scrollTo({
        top: btn.dataset.scroll,
        behavior: 'smooth'
      })
    } else {
      btn.classList.add('rotate-180')
      btn.dataset.scroll = document.documentElement.scrollTop
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
