import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.unlockScroll()
  }

  toggle (ev) {
    let item = document.getElementById(ev.target.dataset.modal)
    let arr = Array.from(
      document.getElementsByClassName('filter_modal')
    ).filter(el => el != item)
    arr.forEach(el => el.classList.add('hidden'))

    if (item.classList.contains('hidden')) {
      this.lockScroll()
      item.classList.remove('hidden')
    } else {
      this.unlockScroll()
      item.classList.add('hidden')
    }
  }

  open (ev) {
    let item = document.getElementById(ev.target.dataset.modal)

    let arr = Array.from(document.getElementsByClassName('filter_modal'))
    arr.forEach(el => el.classList.add('hidden'))

    this.lockScroll()
    item.classList.remove('hidden')
  }

  close (ev) {
    let item = document.getElementById(ev.target.dataset.modal)
    this.unlockScroll()
    item.classList.add('hidden')
  }

  lockScroll () {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`

    // Save the scroll position
    this.saveScrollPosition()

    // Add classes to body to fix its position
    document.body.classList.add(
      'w-full',
      'fixed',
      'inset-x-0',
      'overflow-hidden'
    )

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`
  }

  unlockScroll () {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null

    // Remove classes from body to unfix position
    document.body.classList.remove(
      'w-full',
      'fixed',
      'inset-x-0',
      'overflow-hidden'
    )

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition()

    // Remove the negative top inline style from body
    document.body.style.top = null
  }

  saveScrollPosition () {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop
    document.body.dataset.scrollPosition = this.scrollPosition
  }

  restoreScrollPosition () {
    document.documentElement.scrollTop = document.body.dataset.scrollPosition
  }
}
