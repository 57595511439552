import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    console.log('Connect')
    document.addEventListener('click', this.call)
  }

  disconnect () {
    document.removeEventListener('click', this.call)
  }

  call = event => {
    if (event.target.closest('#filter_container')) return
    let arr = Array.from(document.getElementsByClassName('filter_modal'))
    arr.forEach(el => el.classList.add('hidden'))
    this.unlockScroll()
  }

  unlockScroll () {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null

    // Remove classes from body to unfix position
    document.body.classList.remove(
      'w-full',
      'fixed',
      'inset-x-0',
      'overflow-hidden'
    )

    // Remove the negative top inline style from body
    document.body.style.top = null
  }
}
