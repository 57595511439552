

import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
  async clearFilter() {
    await this.stimulate('BusinessesReflex#clear_filter');
    window.location = '/'
  }
}
