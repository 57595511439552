import { Controller } from 'stimulus'

export default class extends Controller {
  toggle_more (ev) {
    let arr = Array.from(document.getElementsByClassName('filter_modal'))
    arr.forEach(el => el.classList.add('hidden'))
    this.unlockScroll()
    let el = document.getElementById(ev.target.dataset.target)
    el.classList.toggle(this.element.dataset.class)
  }

  unlockScroll () {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null

    // Remove classes from body to unfix position
    document.body.classList.remove(
      'w-full',
      'fixed',
      'inset-x-0',
      'overflow-hidden'
    )

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition()

    // Remove the negative top inline style from body
    document.body.style.top = null
  }

  restoreScrollPosition () {
    document.documentElement.scrollTop = document.body.dataset.scrollPosition
  }
}
