import { Controller } from "stimulus";

export default class extends Controller {
  async connect() {
    this.element.querySelectorAll('.page').forEach(el => {
      if(el.querySelector('a')){
        el.querySelector('a').addEventListener('click', function(event){
          event.preventDefault();

          const link = event.currentTarget;
          const href = link?.getAttribute("href");
          if(href){
            localStorage.setItem("positionYForBusinesess", 0)
            window.location.href = href;
          }
          
        })
      }
    })

    const positionY = await this.getStoredPositionY();
    this.currentTarget = document.querySelector(`.scroll-position-target`)

    if (await this.isBeyoundElement(positionY)) {
      await this.setNullStorage()
    }else {
      await this.scrollingTo(positionY);
    }
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener('beforeunload', function (event) {
      localStorage.setItem("positionYForBusinesess", 0);
  
      return '';
  });
  }

  isBeyoundElement = async (pos) =>{
    return pos > this.currentTarget.getBoundingClientRect().bottom
  }

  async getStoredPositionY() {
    return parseInt(localStorage.getItem('positionYForBusinesess'), 10);
  }

  async scrollingTo(positionY) {
    await window.scrollTo(0, positionY);
    localStorage.removeItem('positionYForBusinesess');
  }

  async setNullStorage(){
    return localStorage.setItem("positionYForBusinesess", 0);
  }

  handleScroll (event) {
    localStorage.setItem("positionYForBusinesess", window.scrollY);
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
