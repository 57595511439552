import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['count']

  connect () {
    this.setCount()
  }

  checkAll () {
    this.setAllCheckboxes(true)
    this.setCount()
  }

  checkNone () {
    this.setAllCheckboxes(false)
    this.setCount()
  }

  onChecked () {
    this.setCount()
  }

  setAllCheckboxes (checked) {
    this.checkboxes.forEach(el => {
      const checkbox = el

      if (!checkbox.disabled) {
        checkbox.checked = checked
      }
    })
  }

  setCount () {
    if (this.hasCountTarget) {
      const count = this.selectedCheckboxes.length
      if (count) {
        this.countTarget.innerHTML = `Выбрано ${count}`
      } else {
        this.countTarget.innerHTML = `Выбрать`
      }
    }
  }

  get selectedCheckboxes () {
    return this.checkboxes.filter(c => c.checked)
  }

  get checkboxes () {
    return new Array(...this.element.querySelectorAll('input[type=checkbox]'))
  }
}
