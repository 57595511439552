import { Controller } from 'stimulus'
import Cleave from 'cleave.js'
require('cleave.js/dist/addons/cleave-phone.ru')

export default class extends Controller {
  connect () {
    new Cleave(this.element, this.configMask(this.element.dataset.type))
  }

  configMask (val) {
    let config = {
      phone: {
        phone: true,
        phoneRegionCode: 'RU'
      },
      date: {
        date: true,
        delimiter: '.',
        datePattern: ['d', 'm', 'Y']
      },
      default: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralPositiveOnly: true,
        delimiter: ' '
      }
    }

    return config[val] || config['default']
  }
}
